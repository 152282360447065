<template>
  <div class="container" style="padding-bottom:70px"  v-wechat-title="$route.meta.title=studio.studio_name" :img-set="studio.studio_cover">
    <div v-show="!loading">
      <van-swipe :autoplay="3000" class="swiper" indicator-color="#00de93">
        <van-swipe-item v-for="item in banner" :key="item.id">
          <van-image class="swiper-img" :src="item.image" alt="banner" />
        </van-swipe-item>
      </van-swipe>
      <van-cell class="mb10">
        <template #title>
          {{ studio.studio_name }}
        </template>
        <!-- <template #default>
          <div class="btn-audition" @click="onForm">预约试听</div>
        </template> -->
      </van-cell>
      <van-cell :title="studio.business_time" icon="clock-o" />
      <a :href="'tel:'+studio.studio_telphone" style="display:block" class="van-hairline--bottom">
        <van-cell
          :title="studio.studio_telphone"
          icon="phone-o"
          is-link
        />
      </a>
      <van-cell
        class="mb10"
        :title="studio.studio_address"
        icon="location-o"
        is-link
        @click="onLocation"
      />

      <div class="section mb10">
        <van-cell icon="QuitOutline1" title="校区介绍" />
        <div class="section-card">
          <div class="content" v-html="studio.studio_description" />
        </div>
      </div>
      
      <div class="section mb10" v-if="commentList.length">
        <van-cell  icon="QuitOutline1" title="相关评价" />
        <div class="section-card comment-list">
          <div
            v-for="item in commentList"
            :key="item.comment_id"
            class="comment-list-item van-hairline--bottom"
          >
            <div class="comment-list-item-hd">
              <van-image class="comment-list-item-img" :src="item.student.student_avatar" />
            </div>
            <div class="comment-list-item-bd">
              <div class="comment-list-item-title">
                <span class="comment-list-item-user van-ellipsis">{{ item.student.student_name }}</span>
                <span class="fz10 ui-c-gray-dark">{{ item.created_at }}</span>
              </div>
              <div class="comment-list-item-star">
                评分
                <van-rate
                  :value="item.rate | formatNumber"
                  size="12"
                  readonly
                  allow-half
                  void-icon="star"
                  void-color="#eee"
                  :color="globalData.theme.color1"
                />
              </div>
              <div class="comment-list-item-star">{{ item.content }}</div>
              <div v-if="item.content_images.length" class="comment-list-item-images">
                <van-image
                  v-for="(image,imageIndex) in item.content_images"
                  :key="imageIndex"
                  class="comment-list-item-image"
                  :src="image"
                  @click="ImagePreview(item.content_images)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部导航栏 -->
    <!-- 底部导航栏 -->
    <Tabbar v-show='!loading' />
    <loadings :loading='loading' />
  </div>
</template>
<script>
import { Swipe, SwipeItem, Rate, ImagePreview } from 'vant'
import Schedule from '@/components/Schedule'
import PageMixin from '@/mixins/page'
import dayjs from 'dayjs'
import no_order from '@/assets/no_order.png'
import { openLocation } from '@/common/wx'
import Loadings from '@/components/loading'
import Tabbar from '@/components/Tabbar'

export default {
  name: 'StudioDetail',
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Rate.name]: Rate,
    Tabbar:Tabbar,
    schedule: Schedule,
    loadings:Loadings,
  },
  mixins: [PageMixin],
  data() {
    return {
      banner: [],
      studio: {},
      teacherList: [],
      recruitList:[],
      commentList: [],
      feeList: [],
      courseList: [],
      weeks: [],
      list: [],
      active: dayjs().format('YYYY-MM-DD'),
      no_order,
      loading:false,
      activeBar:-1
    }
  },
  computed: {
  },
  created() {
    this.loading = true
    this.onDetail()
  },
  methods: {
    ImagePreview,
    onDetail() {
      // this.$toast.loading({
      //   message: '加载中...',
      //   forbidClick: true
      // })
      this.loading = true
      this.$api.campus_detail({ studio_id: this.$route.query.id })
        .then(res => {
          this.banner = res.data.studio.studio_images.map((item, index) => ({ id: index, image: item }))
          this.studio = res.data.studio
          this.teacherList = res.data.studio_teacher
          this.commentList = res.data.studio_comment
          this.courseList = res.data.studio_course
          this.feeList = res.data.studio_course_fee
          this.recruitList = res.data.studio_online_recruit
          document.title = this.studio.studio_name
          this.loading = false
        })
        .finally(() => {
          this.$toast
        })
    },
    onLocation() {
      openLocation({
        longitude: this.studio.studio_lng,
        latitude: this.studio.studio_lat,
        name: this.studio.studio_name,
        address: this.studio.studio_address
      })
    },
    onForm() {
      this.$router.push({ path: '/audition', query: { studio_id: this.$route.query.id, studio_name: this.studio.studio_name }})
    },
    initWeeks(item) {
      return {
        id: '',
        date: item.date,
        txt: item.txt,
        txtDate: item.txt_date,
        dot: item.courses && item.courses.length
      }
    },
    // handleRouterChange(e){
    //   if(e === 4){
    //     let num = localStorage.getItem('profile_employee')
    //     if(num == 1){
    //       this.$router.push({path:'/profile'})
    //     }else{
    //       this.$router.push({path:'/user'})
    //     }
    //   }else if(e === 1){
    //     this.$router.push({path:'/recruit',query:{appid:localStorage.getItem('appid')}})
    //   }else if(e === 2){
    //     this.$router.push({path:'/schedule'})
    //   }else if(e === 3){
    //     this.$router.push({path:'/mall'})
    //   }else if(e === 0){
    //     this.$router.push({path:'/home'})
    //   }
    // }
  }
}
</script>
<style lang="less" scoped>
@import "~@styles/theme.less";
.swiper {
  &-img {
    width: 100%;
    height: 180px;
  }
}
.teacher-list{
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}
.teacher-item {
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  width: 80px;
  height: 80px;
  padding-top: 10px;
  margin-left: 15px;
  &-img {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 12px;
  }
  &-name {
    text-align: center;
    font-size: 12px;
    color: #474747;
  }
}

.content {
  font-size: 14px;
  color: #666;
  padding: 20px 15px;
  word-wrap: break-word;
  word-break: break-all;
  background: #fff;
}

.section {
  background: #fff;
}
.comment-list-item {
  box-sizing: border-box;
  padding: 10px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  &-bd {
    flex: 1;
  }
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-user {
    width: 160px;
    font-size: 14px;
    color: #666;
  }
  &-img {
    width: 25px;
    height: 25px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
  }
  &-star {
    font-size: 12px;
    color: #666;
    margin-bottom: 5px;
  }
  &-desc {
    margin-bottom: 5px;
  }
  &-images {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &-image {
    width: 90px;
    height: 90px;
    margin-right: 10px;
  }
}

.course-list {
  box-sizing: border-box;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.course-list-item {
  margin-right: 10px;
  width: 220px;
  &-img {
    width: 220px;
    // margin-bottom: 10px;
  }
  &-title {
    text-align: center;
    font-size: 14px;
    color: #474747;
  }
}
.fee-list{
  box-sizing: border-box;
  padding: 10px 15px;
}
.fee-list-item{
  box-sizing: border-box;
  padding: 10px 15px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  color: #fff;
  font-size: 14px;
  width: 100%;
  height: 140px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  &-header{
    font-size: 18px;
    font-weight: bold;
    text-align: right;
  }
  &-footer{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &-bottom-left{
    &--1{

    }
    &--2{
      font-size: 18px;
    }
  }
  &-bottom-right{

  }
}
.btn-audition{
  display: inline-block;
  font-size: 12px;
  color: #fff;
  background: @ui-color1;
  border-radius: 2PX;
  padding: 2px 16px;
}

 .schedule-list{
    min-height: 220px;
    padding: 10px 15px;
  }
  .schedule-list-item{
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-hd{

      &-title{
        color: #333;
        font-size: 12px;
      }
      &-txt{
        // color: #999;
        color: #333;
        font-size: 14px;
      }
    }
    &-bd{
      flex: 1;
      text-align: center;
    }
    &-ft{
      &-btn{
        display: inline-block;
        min-width: 45px;
        text-align: center;
        padding: 2px 4px;
        border-radius: 20PX;
        font-size: 10px;
        line-height: 20px;
        color: #fff;
        background: #00DE93;
        margin-bottom: 4px;
      }
      .disabled{
        color: #fff;
        background: #bbb;
      }
      &-txt{
        // color: #999;
        color: #333;
        font-size: 12px;
        text-align: right;
      }
    }
  }
  .no-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-sizing: border-box;
    min-height: 220px;
    text-align: center;
    &-image{
      width: 96px;
      height: 96px;
      background: #fff;
      margin-bottom: 10px;
    }

  }
</style>

