<template>
  <div class="schedule-wrapper">
    <div class="schedule">
      <div class="schedule-header van-hairline--bottom">
        <div class="schedule-header-hd">课程表</div>
        <div class="schedule-header-bd" @click="onCalendarOpen">{{ active }}</div>
        <div class="schedule-header-ft">
          <slot name="right"><van-icon class="iconfont" class-prefix="icon" name="CalendarOutline" /></slot>
        </div>
      </div>
      <div class="schedule-weekdays van-hairline--bottom">
        <div v-for="(item,index) in weeks" :key="index" class="schedule-weekday" :class="{'active':active === item.date}" @click="onItemClick(item)">
          <div class="schedule-weekday-hd">{{ item.txt }}
          </div>
          <div class="schedule-weekday-bd">
            <div class="schedule-weekday-bd-txt" :class="{'schedule-weekday-activebg':active === item.date}">
              <span>{{ item.txtDate }}</span>
              <div :class="active === item.date?'schedule-weekday-bd-txt-dotbg':'schedule-weekday-bd-txt-dot'" v-if="item.dot"></div>
            </div>
          </div>
        </div>
      </div>
      <slot />
    </div>
    <van-calendar v-model="show" :default-date="defaultDate" :color="globalData.theme.color" :min-date="minDate" :show-confirm="false" @select="onSelect" />
  </div>
</template>

<script>
import { Calendar, Loading } from 'vant'
import dayjs from 'dayjs'
export default {
  name: 'Schedule',
  components: {
    [Calendar.name]: Calendar,
    [Loading.name]: Loading
  },
  model: {
    prop: 'active',
    event: 'change'
  },
  props: {
    active: {
      type: String,
      default: dayjs().format('YYYY-MM-DD')
    },
    weeks: {
      type: Array,
      default: () => []
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      show: false,
      classList: [],
      courses: [],
      defaultDate: dayjs().toDate(),
      minDate: dayjs().subtract(1, 'month').toDate()

    }
  },
  computed: {
    globalData() {
      return this.$store.getters.globalData
    }
  },
  methods: {
    onItemClick({ date }) {
      const value = date
      this.$emit('change', value)
    },
    onSelect(date) {
      this.show = false
      const value = dayjs(date).format('YYYY-MM-DD')
      this.$emit('change', value)
    },
    onCalendarOpen() {
      this.show = true
    }
  }
}
</script>
<style lang="less" scoped>

  .schedule{
    background: #fff;
    &-header{
      display: flex;
      align-items: center;
      justify-items: flex-start;
      padding:0 15px;
      &-bd{
        flex: 1;
        height: 44px;
        font-weight: 500;
        line-height: 44px;
        text-align: center;
        font-size: 14px;
      }
    }
    &-weekdays{
      display: flex;
    }
    &-weekday{
      flex: 1;
      width: 14.2%;
      font-size: 12px;
      text-align: center;
      &-hd{
        position: relative;
        line-height: 30px;
      }
      &-activebg{
        background: #00DE93;
        color: #fff;
      }
      &-bd{
        padding: 0 0 10px;
        &-txt{
          width: 25px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          border-radius: 50%;
          position: relative;
          margin: 0 auto;
          &-dot{
            position: absolute;
            bottom: -6px;
            left: 50%;
            margin-left: -2px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #000;
          }
          &-dotbg{
            position: absolute;
            bottom: -6px;
            left: 50%;
            margin-left: -2px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #00DE93;
          }
        }
      }
    }
    .active{
      // background: #00DE93;
      color: #00DE93;
    }
  }

  .date-dot{
    position: absolute;
    top: 4px;
    right: 4px;
    width: 8px;
    height: 8px;
    background-color: #ee0a24;
    border-radius: 100%;
  }

   .no-data{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-sizing: border-box;
    min-height: 220px;
    text-align: center;
    &-image{
      width: 96px;
      height: 96px;
      background: #fff;
      margin-bottom: 10px;
    }

  }
</style>

